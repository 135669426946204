import { extendTheme } from '@mui/joy';

export default extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          mainChannel: '#4c81ec',
          50: '#e4f1ff',
          100: '#c0dcff',
          200: '#98c7ff',
          300: '#72b1fe',
          400: '#5ba0fe',
          500: '#4d90fb',
          600: '#4c81ec',
          700: '#486fd8',
          800: '#455dc5',
          900: '#3e3da4',
          softBg: 'var(--joy-palette-primary-50)',
          softHoverBg: 'var(--joy-palette-primary-100)',
          softActiveBg: 'var(--joy-palette-primary-200)',
          softColor: 'var(--joy-palette-text-primary)',
        },
        neutral: {
          50: '#e7eaf3',
          100: '#c1cae3',
          200: '#99a8d0',
          300: '#7086bd',
          400: '#506cb0',
          500: '#2d53a3',
          600: '#264b9a',
          700: '#1c428e',
          800: '#133882',
          900: '#03276c',
          softBg: 'var(--joy-palette-neutral-50)',
          softHoverBg: 'var(--joy-palette-neutral-100)',
          softActiveBg: 'var(--joy-palette-neutral-200)',
          outlinedDisabledColor: 'var(--joy-palette-neutral-400)',
        },
        text: {
          secondary: 'var(--joy-palette-neutral-400)',
          tertiary: 'var(--joy-palette-neutral-200)',
        },
        background: {
          body: '#F6F8FB',
          surface: 'var(--joy-palette-common-white)',
        },
        divider: 'rgba(19, 56, 130, 0.1)',
      },
    },
  },
  components: {
    JoyCard: {
      defaultProps: {
        variant: 'plain',
      },
      styleOverrides: {
        root: {
          background: 'var(--joy-palette-common-white)',
          boxShadow: 'var(--joy-shadow-sm)',
        },
      },
    },
  },
});
