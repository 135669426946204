import { CssBaseline, CssVarsProvider, StyledEngineProvider, ThemeProvider } from '@mui/joy';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LazyMotion, domMax } from 'framer-motion';
import { Provider as JotaiProvider } from 'jotai';
import { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import theme from './theme';

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

export default function Providers({ children }: { children: ReactNode }) {
  return (
    <StyledEngineProvider injectFirst>
      <CssVarsProvider theme={theme}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <LazyMotion features={domMax} strict>
            <BrowserRouter>
              <QueryClientProvider client={queryClient}>
                <JotaiProvider>{children}</JotaiProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </BrowserRouter>
          </LazyMotion>
        </ThemeProvider>
      </CssVarsProvider>
    </StyledEngineProvider>
  );
}
