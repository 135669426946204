import {
  browserTracingIntegration,
  captureException,
  getCurrentScope,
  init,
  makeBrowserOfflineTransport,
  makeFetchTransport,
} from '@sentry/react';
import axios, { AxiosError, isAxiosError } from 'axios';
import { matchPath } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';
import { areSetsEqual } from './utils/areSetsEqual';
import { environment } from './utils/environment';

const key = 'sentry-user-id';

const sentrySessionId = uuidV4();

const sentryUserId =
  localStorage?.getItem(key) ??
  (() => {
    const id = uuidV4();
    localStorage?.setItem(key, id);
    return id;
  })();

const sentryRequestHeaders = {
  'sentry-custom-session-id': sentrySessionId,
  'sentry-custom-user-id': sentryUserId,
};

const clientHandledErrors = [
  {
    pathname: '/mercury-api/:tenantKey/service-offerings/:serviceOfferingId',
    searchParams: ['password', 'key'],
    statusCode: 400,
  },
  {
    pathname: '/mercury-api/:tenantKey/services/:serviceId/sbb/book-trip',
    statusCode: 404,
  },
];
const ignoredAxiosErrorCodes = [AxiosError.ERR_CANCELED, AxiosError.ERR_NETWORK, AxiosError.ECONNABORTED];

const shouldCaptureException = (error: unknown) => {
  if (isAxiosError(error)) {
    if (error.code && ignoredAxiosErrorCodes.includes(error.code)) {
      return false;
    }

    if (error.response?.config.url) {
      const url = new URL(error.response.config.url, window.location.origin);
      for (const { pathname, searchParams, statusCode } of clientHandledErrors) {
        if (
          matchPath(pathname, url.pathname) &&
          areSetsEqual(new Set(url.searchParams.keys()), new Set(searchParams)) &&
          error.response.status === statusCode
        ) {
          return false;
        }
      }
    }
  }
  return true;
};

const instrumentAxios = () => {
  Object.entries(sentryRequestHeaders).forEach(([header, value]) => (axios.defaults.headers.common[header] = value));
  axios.interceptors.response.use(
    (i) => i,
    (error) => {
      if (shouldCaptureException(error)) {
        captureException(error);
      }
      return Promise.reject(error);
    },
  );
};

export function setupSentry() {
  init({
    dsn: environment === 'localhost' ? '' : `${import.meta.env.VITE_SENTRY_DSN}`,
    transport: makeBrowserOfflineTransport(makeFetchTransport),
    integrations: [browserTracingIntegration()],
    tracesSampleRate: 0.1,
    autoSessionTracking: false,
    environment,
    release: `${import.meta.env.VITE_FRONTEND_VERSION}`,
    normalizeDepth: 10,
  });
  instrumentAxios();
  getCurrentScope().setTag('session_id', sentrySessionId).setTag('user_id', sentryUserId);
}
